
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Table } from "@/utils/decorator";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const message = namespace("message");

@Component({})
@Table("loadListData")
export default class Customer extends Vue {
  @message.Action getRechargeOrder;

  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm = {
    beginRechargeTime: "",
    endRechargeTime: ""
  };
  rechargeList: any[] = [];
  searchList: any[] = [
    {
      label: t("v210831.recharge-date"),
      type: "daterange",
      value: "",
      prop: "rechargeTime"
    }
  ];
  created() {
    this.init();
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "rechargeTime") {
          this.searchForm.beginRechargeTime = item.value ? item.value[0] : "";
          this.searchForm.endRechargeTime = item.value ? item.value[1] : "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
      }
    });
    this.loadListData();
  }
  handleLinkView(row) {
    this.$router.push(`/finance/tmall-bill-view/${row.billNo}`);
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.getRechargeOrder({
      orderItems: this.mix_sortParams,
      ...this.searchForm,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum
    }).then(data => {
      this.rechargeList = data.data.list;
      return data;
    });
  }
  handleView(row) {
    this.$router.push(`/setting/customer-view/${row.customerCode}?vehicleCode=${row.vehicleCode}`);
  }
  init() {
    this.loadListData();
  }
}
